/* Genel stil */
.quotes-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Filter container'ın konumunu ayarlamak için */
}

.quotes-container h2 {
  text-align: left; /* Başlığı sola hizalar */
  margin-bottom: 20px; /* Başlığın altında biraz boşluk bırakır */
}

.quotes-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-item {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
}

.quote-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.quote-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-number {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  line-height: 1.4;
}

.quote-number span {
  display: block;
}

.quote-number .quote-label {
  font-size: 16px;
  color: #333;
}

.quote-number .quote-value {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}

.quote-number .company-name {
  font-size: 14px;
  color: #555;
}

.expand-toggle {
  display: grid;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}

.quote-details {
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}

.quote-details p {
  margin: 5px 0;
}

.quote-files {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.file-previews {
  display: flex;
  flex-wrap: wrap; /* Eğer çok fazla dosya varsa, alt satıra geçmelerini sağlar */
  gap: 10px; /* Dosyalar arasındaki boşluk */
}
.file-preview {
  position: relative;
  width: 120px; /* Genişlik arttırıldı */
  height: 120px; /* Yükseklik arttırıldı */
  border: 3px solid #dddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-icon-text {
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}

.file-download-button {
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.expandable-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.edit-delete-buttons {
  justify-content: center;
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  align:right;
  transition: background-color 0.3s ease;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}
.file-download-button:hover {
  background-color: #0056b3;
}

.yarl__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yarl__button {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yarl__button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.yarl__button_prev {
  left: 10px;
}

.yarl__button_next {
  right: 10px;
}

.yarl__slide img {
  max-width: 80%;
  max-height: 80%;
  margin: auto;
  display: block;
}

/* Tarih filtreleme konteyneri */
.filter-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-container label {
  font-size: 14px;
  margin: 0;
}

.filter-container input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Mobil uyumluluk için medya sorguları */
@media (max-width: 768px) {
  .quotes-container {
    padding: 20px;
  }

  .quote-item {
    padding: 15px;
  }

  .quote-number {
    font-size: 16px;
  }

  .quote-details {
    font-size: 13px;
  }

  .quote-files {
    gap: 5px;
  }

  .file-preview {
    width: 100px;
    height: 100px;
  }

  .yarl__button {
    width: 35px;
    height: 35px;
  }

  .filter-container {
    top: 10px;
    right: 10px;
    padding: 8px;
    font-size: 13px;
  }

  .filter-container input {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .quotes-container {
    padding: 10px;
  }

  .quote-item {
    padding: 10px;
  }

  .quote-number {
    font-size: 14px;
  }

  .quote-details {
    font-size: 12px;
  }

  .quote-files {
    flex-direction: column;
    gap: 8px;
  }
  .yarl__button {
    width: 30px;
    height: 30px;
  }

  .filter-container {
    top: 5px;
    right: 5px;
    padding: 5px;
    font-size: 12px;
  }

  .filter-container input {
    font-size: 12px;
  }
}
