.admin-dashboard {
    padding: 20px;
}

h2, h3 {
    text-align: center;
}

.add-user-button {
    display: block;
    margin: 20px auto;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    max-width: 200px; /* Butonun maksimum genişliğini sınırlıyoruz */
    width: 100%; /* Genişliği %100 yaparak, max-width ile sınırlı kalmasını sağlıyoruz */
}

.add-user-button:hover {
    background-color: #0056b3;
}

.user-form {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
}

.user-form input,
.user-form select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button.save-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
}

button.save-button:hover {
    background-color: #45a049;
}

button.delete-button {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

button.delete-button:hover {
    background-color: #e53935;
}

button.edit-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button.edit-button:hover {
    background-color: #ff8f00;
}

.user-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.user-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-card h4 {
    margin: 0;
    font-size: 18px;
}

.user-card button {
    margin-top: 10px;
}
