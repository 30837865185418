.myContainer {
  display: flex;
  justify-content: space-between; /* Menü ve kullanıcı bilgilerini hizalamak için */
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px;
}

.header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header h1 {
  font-size: 24px;
  color: #ffffff;
  margin: 0;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info p {
  margin: 0;
  margin-right: 10px;
  color: #ffffff;
  font-weight: bold;
}

.icon-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  color: #dc3545;
}

.Navbar {
  padding: 10px 20px;
  background: linear-gradient(135deg, #6d5eac, #8f83ff);
}

.Navbar .nav-link {
  color: #ffffff;
  margin-right: 15px;
}

.Navbar .nav-link:hover {
  color: #dc3545;
}

/* Mobil görünüm için düzenlemeler */
@media (max-width: 700px) {
  .myContainer {
    flex-direction: column;
    align-items: center;
  }

  .header {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 10px;
    text-align: center;
  }

  .header h1 {
    font-size: 20px; /* Daha küçük ekranlar için font boyutunu küçült */
  }

  .user-info {
    margin-top: 10px;
  }

  .Navbar .nav-link {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
