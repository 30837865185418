.profile {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile h2 {
  text-align: center;
  margin-bottom: 20px;
}

.profile .form-group {
  margin-bottom: 20px; /* Artırılmış boşluk */
}

.profile label {
  display: block;
  margin-bottom: 8px; /* Etiketler ile input arasındaki boşluk */
  font-weight: bold;
}

.profile input {
  width: 100%;
  padding: 10px; /* Artırılmış padding */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil et */
}

.profile button {
  width: 100%;
  padding: 12px; /* Artırılmış padding */
  margin-top: 10px; /* Butonlar arasındaki boşluk */
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.profile button:hover {
  background-color: #0056b3;
}
