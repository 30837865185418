/* Arka plan ve sayfa düzeni */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6d5eac, #8f83ff);
  background-size: cover;
  padding: 20px;
}

.login-page-base-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 100%;
  backdrop-filter: blur(10px);
  text-align: center; /* Başlık ve formu ortalamak için eklendi */
}

/* Logo */
.login-logo {
  width: 150px; /* Logonun genişliği, ihtiyaçlarınıza göre ayarlayın */
  margin-bottom: 20px; /* Başlık ve logo arasındaki boşluğu ayarlamak için */
}

/* Başlık */
.login-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #4a4a4a;
}

/* Form ve giriş alanları */
.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.login-input {
  padding: 12px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.login-input:focus {
  outline: none;
  border-color: #8f83ff;
  box-shadow: 0 0 5px rgba(143, 131, 255, 0.5);
}

/* Hata mesajı */
.error-message {
  color: #ff4d4f;
  margin-bottom: 20px;
}

/* Giriş butonu */
.login-button {
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  background-color: #8f83ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-button:hover {
  background-color: #6d5eac;
  transform: translateY(-3px);
}
